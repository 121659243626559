.contenu-form-contact {
  h2 {
    margin-bottom: 20px;
  }
  p {
    font-size: 1.2em;
  }
  div:first-child {
    background-color: $bleu-300;
  }
  form {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    grid-template-rows: repeat(5, auto);
    label {
      display: none;
    }
    .form-field:nth-child(2) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    .form-field:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
    .form-field:nth-child(4) {
      grid-column: 1/2;
      grid-row: 3/4;
    }
    .form-field:nth-child(5) {
      grid-column: 1/2;
      grid-row: 4/5;
    }
    .form-field:nth-child(6) {
      grid-column: 2/4;
      grid-row: 1/5;
    }
    .button-wrapper {
      grid-column: 1/4;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
}
