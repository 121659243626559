#liste-realisations {
    .contenu {
        min-height: 130px;
    }
    .mockup {
        bottom: -35px;
        right: 0;
        width: 40%;
        overflow: hidden;
        svg {
            height: 190px;
        }
    }
    .w-60 {
        width: 60%;
    }
    .liste-check {
        .puce {
            &:before {
                content: "";
                z-index: -1;
                left: -5px;
                top: 5px;
                position: absolute;
                display: block;
                height: 20px;
                width: 20px;
                background-color: $bleu-300;
                border-radius: 100%;
            }
            svg {
                height: 20px;
                path {
                    fill: $bleu-500 !important;
                }
            }
        }
    }
}
