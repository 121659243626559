@font-face {
  font-family: "Font Awesome 5 Free";
  src: url("../fonts/fa-solid-900.woff2") format("woff2"),
    url("../fonts/fa-solid-900.woff") format("woff");
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  color: $noir;
  font-family: "Lato", sans-serif;
}

:target:before {
  content: "";
  display: block;
  height: 65px;
  margin-top: -65px;
}

.section {
  padding: 60px 0;
  .titre-f {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 2.2em;
    margin-bottom: 30px;
    color: $bleu-500;
    border-bottom: 5px solid $jaune;
    svg {
      margin-right: 20px;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.5em;
    }
  }
}

.text-baloo {
  font-family: "Baloo 2";
}

// COLOR TEXT

.text-bleu-300 {
  color: $bleu-300 !important;
}
.text-bleu-400 {
  color: $bleu-400 !important;
}
.text-bleu-500 {
  color: $bleu-500 !important;
}
.text-bleu-600 {
  color: $bleu-600 !important;
}
.text-bleu-700 {
  color: $bleu-700 !important;
}
.text-jaune {
  color: $jaune !important;
}
.text-jaune-light {
  color: $jaune-light !important;
}
.text-gris {
  color: $gris !important;
}
.text-gris-light {
  color: $gris-light !important;
}
.text-noir {
  color: $noir !important;
}

.shadow-bleu {
  box-shadow: 0px 0px 6px 0px rgba(0, 110, 179, 0.25);
}

.z-index-1 {
  z-index: 1 !important;
}

.display-5 {
  font-size: 3em;
}
.display-6 {
  font-size: 2.5em;
}
.display-7 {
  font-size: 2em;
}
.display-8 {
  font-size: 1.5em;
}
.display-9 {
  font-size: 1.2em;
}
.display-10 {
  font-size: 1.1em;
}
.display-initial * {
  font-size: 1em !important;
}

.mb-6 {
  margin-bottom: 70px !important;
}

.bg-white {
  background-color: #fff !important;
}
.bg-bleu-300 {
  background-color: $bleu-300 !important;
}
.bg-bleu-400 {
  background-color: $bleu-400 !important;
}
.bg-bleu-500 {
  background-color: $bleu-500 !important;
}
.bg-bleu-600 {
  background-color: $bleu-600 !important;
}
.bg-bleu-700 {
  background-color: $bleu-700 !important;
}
.bg-gris {
  background-color: $gris-light !important;
}

.decoration {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.titres-symbol {
  h2 {
    margin-bottom: 15px;
    color: $bleu-500;
    font-size: 1.3em;
    font-family: "Baloo 2";
    font-weight: 600;
  }
}
