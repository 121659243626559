#services-formations {
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
    img {
        object-fit: cover;
        @include media-breakpoint-down(md) {
            height: 300px;
            width: 300px;
        }
    }
    .decoration {
        top: -90px;
        right: -180px;
        width: 400px;
        transform: rotate(90deg);
        opacity: 0.3;
        .forme {
            display: none;
        }
    }
}
