#nous-rejoindre-equipe {
    .photo {
        &:nth-child(even) {
            width: 22%;
            margin-top: 30px
        }
        &:nth-child(odd) {
            width: 17%;
            margin-top: -100px;
        }
    }
    .join-us {
        height: 200px;
        width: 200px;
        border: 4px dashed $bleu-400;
        transition: all 0.3s;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            border: 4px dashed $bleu-500;
            color: $bleu-500 !important;
        }
        .icon {
            transition: transform 0.5s;
        }
        &:hover .icon {
            transform: rotate(90deg);
        }
    }
}
