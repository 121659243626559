#home-services {
    h3 {
        font-size: 1.3em;
        &:after {
            content: "";
            display: block;
            width: 30px;
            height: 5px;
            background-color: $jaune;
            border-radius: 10px;
            margin: 10px auto;
        }
    }
    .icon:before {
        content: "";
        z-index: -1;
        left: -30px;
        bottom: 10px;
        position: absolute;
        display: block;
        height: 80px;
        width: 80px;
        background-color: $bleu-300;
        border-radius: 100%;
    }
    .decoration {
        top: -150px;
        right: -150px;
        width: 360px;
        transform: rotate(-40deg);
        .forme {
            fill: $bleu-300;
            opacity: 0.7;
        }
    }
}
