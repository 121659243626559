#page-actualite {
    .icon {
        width: 60px;
    }
    ul {
        li {
            margin: 0;
            padding: 5px 0 5px 40px;
            list-style: none;
            background-image: url('../icon/correct.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 20px;
        }
    }
    line-height: 180%;
    img {
        max-width: 100%;
        margin: 20px 0;
    }
}
