// nav {
//   @include media-breakpoint-down(md) {
//     background-color: #fff;
//   }
//   .navbar-brand img {
//     display: none;
//     height: 60px;
//     @include media-breakpoint-down(md) {
//       display: inline;
//     }
//   }
//   &.scroll {
//     box-shadow: 0px 0px 6px 0px rgba(0, 110, 179, 0.25);
//     background-color: #fff;
//     .navbar-brand img {
//       display: inline;
//     }
//     .nav-item .nav-link {
//       color: $bleu-700 !important;
//     }
//   }
//   .nav-item {
//     font-size: 1.1em;
//     margin: 0 10px;
//     white-space: nowrap;
//     @media (max-width: 1100px) {
//       margin: 0 5px;
//     }
//     .nav-link {
//       color: $bleu-300 !important;
//       display: inline-block;
//       &:after {
//         transition: all 0.2s;
//         content: "";
//         display: block;
//         width: 0%;
//         height: 4px;
//         background-color: $bleu-500;
//         border-radius: 20px;
//       }
//       &:hover {
//         &:after {
//           width: 100%;
//         }
//       }
//     }
//     .nav-link.active {
//       font-weight: 500;
//       &:after {
//         content: "";
//         display: block;
//         width: 100%;
//         height: 4px;
//         background-color: $jaune;
//         border-radius: 20px;
//       }
//     }
//   }
//   .navbar-collapse.show .navbar-nav,
//   .navbar-collapse.collapsing {
//     .nav-item {
//       .nav-link {
//         color: $bleu-700 !important;
//       }
//     }
//   }
// }

nav {
  @include media-breakpoint-down(lg) {
    background-color: #fff;
  }
  .navbar-brand img {
    display: none;
    height: 60px;
    @include media-breakpoint-down(lg) {
      display: inline;
    }
  }
  &.scroll {
    box-shadow: 0px 0px 6px 0px rgba(0, 110, 179, 0.25);
    background-color: #fff;
    .navbar-brand img {
      display: inline;
    }
    .nav-item .nav-link {
      color: $bleu-700 !important;
    }
  }
  //ITEM
  .nav-item {
    padding: 0 10px;
    font-size: 1.1em;
    a {
      color: #e2edf7 !important;
      animation: all 0.5s;
      display: inline-block;
    }
  }

  // DROPDOWN

  .dropdown-menu {
    margin: 0;
    white-space: nowrap;
    .nav-link {
      color: $noir !important;
      font-weight: 400;
    }
    @media (max-width: 440px) {
      white-space: normal;
    }
  }
  @include media-breakpoint-up(md) {
    .dropdown:hover > .dropdown-menu {
      display: block;
    }

    .dropdown > .dropdown-toggle:active {
      pointer-events: none;
    }
  }
  .animate-link {
    font-weight: 500;
  }
  .animate-link::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    background: $bleu-500;
    transition: width 0.3s;
  }

  .active.nav-link::after,
  .animate-link:hover::after {
    width: 100%;
  }
  .nav-link.active {
    font-weight: 500;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: $jaune;
      border-radius: 20px;
    }
  }
  .navbar-collapse.show .navbar-nav,
  .navbar-collapse.collapsing {
    .nav-item {
      .nav-link {
        color: $bleu-700 !important;
      }
    }
  }
}
