.blog-article {
  .small-header {
    .share {
      color: white;
      margin: 0 2px;
      &:hover {
        text-decoration: none;
        color: $bleu-600;
        transition: color 0.2s linear;
      }
    }
  }
  .article-body {
    display: flex;
    flex-direction: column;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $bleu-600;
      align-self: flex-start;
    }
    p img {
      height: 300px;
      padding-top: 30px;
      margin: auto;
      display: block;
    }
    p p,
    p em {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .share-block {
    @media (max-width: 720px) {
      width: 80% !important;
    }
    border-top: 2px solid rgba($color: #cccccc, $alpha: 1);
    p {
      font-size: 22px;
      @media (max-width: 720px) {
        font-size: 16px;
        margin-top: 30px !important;
      }
    }
    .share {
      margin: 0 8px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
