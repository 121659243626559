#home-actualites {
    .carte-actualite {
        text-decoration: none !important;
        transition: all .5s;
        .card-img-top {
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
        &:hover {
            transform: scale(1.03);
        }
        .overlay {
            cursor: pointer;
            top:0;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
        }
        &:hover .overlay {
            opacity: 0.9;
            visibility: visible;
        }
    }
    .decoration {
        top: -50px;
        right: -190px;
        width: 340px;
        transform: rotate(-70deg);
        .forme {
            fill: $bleu-300;
            opacity: 0.7;
        }
    }

}