.carte-formation {
    text-decoration: none !important;
    color: initial !important;
    .overlay {
        cursor: pointer;
        top:0;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
    }
    &:hover .overlay {
        opacity: 0.9;
        visibility: visible;
    }
}