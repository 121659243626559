#home-avantages {
    img {
        @include media-breakpoint-down(md) {
            height: 60px !important;
        }
    }
    .decoration {
        top: 10px;
        left: -100px;
        width: 280px;
        transform: rotate(-80deg);
        .forme {
            display: none;
        }
        .contour {
            opacity: 0.3;
            stroke: $bleu-400 !important;
        }
    }
}