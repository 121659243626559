#home-header {
  height: 100vh;
  background-size: cover;
  overflow: hidden;
  background-position: center;
  padding: 100px 0 30px;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
  margin-right: -10%;
  margin-left: -10%;
  padding-left: 10%;
  padding-right: 10%;
  @include media-breakpoint-down(md) {
    height: 100% !important;
  }
  img {
    width: 300px;
    @include media-breakpoint-down(md) {
      width: 200px;
    }
  }
  .container {
    font-size: 1.8em;
    @include media-breakpoint-down(md) {
      font-size: 1.1em;
    }
  }
  a {
    opacity: 0.8;
    height: 70px;
    transition: opacity 0.3s ease;
    display: block;
    svg {
      animation: 1s ease 1s infinite alternate slidein;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover {
      opacity: 1;
    }
  }
  @keyframes slidein {
    from {
      bottom: 0;
    }
    to {
      bottom: 20px;
    }
  }

  .decoration {
    top: -70px;
    left: -300px;
    width: 600px;
    transform: rotate(80deg);
    .forme {
      opacity: 0.1;
      fill: $gris-light !important;
    }
    .contour {
      opacity: 0.3;
      stroke-width: 0.3px;
      stroke: $gris-light !important;
    }
  }
}
