#formulaire {
    .form-radio {
        .form-data {
            display: flex;
            .radio {
                margin: 0 20px;
            }
        }
    }
    .button-wrapper {
        margin:auto;
    }
    .radio label {
        color: $bleu-500;
    }
    .autre input {
        width: 250px;
    }
}