#equipe-valeurs {
    img {
        object-fit: cover;
    }
    h3:after {
        content: "";
        display: block;
        width: 50px;
        height: 5px;
        background-color: $bleu-500;
        border-radius: 10px;
        margin: 10px 0;
        @include media-breakpoint-down(sm) {
            margin: 10px auto;
        }
    }
    .valeur:last-child {
        margin-bottom: 0 !important;
    }
    .decoration {
        opacity: 0.7;
        &.deco1 {
            top: 100px;
            right: -70px;
            width: 230px;
            height: 150px;
            transform: rotate(40deg);
            .forme {
                fill: $bleu-300;
                opacity: 0.7;
            }
        }
        &.deco2 {
            bottom: 30px;
            left: -100px;
            width: 200px;
            height: 150px;
            transform: rotate(-40deg);
            .forme {
                fill: $bleu-300;
                opacity: 0.6;
            }
        }
    }
}
