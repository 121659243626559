.article-preview {
  width: 400px;
  margin: 0 15px 30px;
  background-color: #f3f3f3;
  -webkit-box-shadow: 2px 2px 6px 4px rgba(243, 243, 243, 1);
  -moz-box-shadow: 2px 2px 6px 4px rgba(243, 243, 243, 1);
  box-shadow: 2px 2px 6px 4px rgba(243, 243, 243, 1);
  border: none;
  .article-title {
    font-weight: bolder;
    font-size: 22px;
    text-decoration: none;
    display: inline-block;
    color: $bleu-500 !important;
    &:hover {
      opacity: 0.7;
      transition: all 0.2s ease-in;
    }
  }
  .article-author {
    color: #808094;
    font-size: 17px;
  }
  .img-link {
    display: inline-block;
    position: relative;
    .card-img-top {
      height: 200px !important;
      &:hover {
        opacity: 0.7;
        transition: all 0.2s ease;
      }
    }
    button {
      height: min-content;
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;
    p:nth-child(3) {
      flex-grow: 2;
    }
  }
}
