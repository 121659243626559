#equipe-presentation {
    .chiffre {
        padding-bottom: 5px;
        margin-bottom: 15px;
        border-bottom: $jaune 4px solid;
    }
    .decoration {
        top: 50px;
        left: -350px;
        width: 500px;
        transform: rotate(-70deg);
        opacity: 0.7;
        .forme {
            fill: $bleu-300;
            opacity: 0.7;
        }
    }
}