footer {
  z-index: 10;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px 150px;
  background-color: $bleu-700;
  @media (max-width: 1024px) {
    padding: 30px 20px;
  }
  img {
    filter: brightness(100);
    width: 150px;
  }
  a {
    color: #fff;
    height: min-content;
    &:hover {
      color: white;
    }
  }
  svg:hover {
    transform: scale(1.05);
    transition: transform 0.2s linear;
  }
  p {
    font-size: 18px;
  }
  a.btn {
    color: white;
    &:hover {
      background-color: $bleu-600 !important;
      color: white;
    }
  }
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .social-networks,
  .contact {
    .link {
      margin-right: 18px;
      margin-top: 3px;
    }
  }
  .contact {
    .link {
      position: relative;
      p {
        display: none;
      }
      &:hover {
        p {
          width: max-content;
          margin-top: 5px;
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .btn {
      svg {
        margin-right: 4px;
      }
    }
  }
  .pages-shortcut {
    @media (max-width: 820px) {
      flex-direction: column !important;
      align-items: flex-start;
    }
  }
}
