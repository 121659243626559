#home-realisations {
    background-color: $gris-light;
    .realisations {
        .carte-realisation {
            flex: 1 0;
            position: relative;
            z-index: 10;
            .card-img-top {
                max-height: 300px;
                object-fit: cover;
            }
        }
        .galerie-realisation {
            position: relative;
            z-index: 10;
            flex: 2 0;
            .item-realisation {
                .overlay {
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.3s;
                }
                &:hover .overlay {
                    opacity: 0.9;
                    visibility: visible;
                }
                height: 250px;
                img {
                    object-fit: cover;
                }
                div {
                    bottom: 0;
                }
                &:nth-child(1),
                &:nth-child(4) {
                    width: calc(65% - 1rem);
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
                &:nth-child(2),
                &:nth-child(3) {
                    width: calc(35% - 1rem);
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
    }
    .decoration {
        z-index: 1;
        &.deco1 {
            top: 100px;
            right: -40px;
            width: 200px;
            height: 150px;
            transform: rotate(40deg);
            .forme {
                fill: $bleu-300;
                opacity: 0.7;
            }
        }
        &.deco2 {
            bottom: 30px;
            left: -100px;
            width: 200px;
            height: 150px;
            transform: rotate(-40deg);
            .forme {
                fill: $bleu-300;
                opacity: 0.6;
            }
        }
    }
}
