#page-formation {
    h3 {
        font-size: 1.3em;
        font-weight: 600;
        color: $bleu-500;
        margin-bottom: 20px;
    }
    ul {
        list-style: none;
        padding-left: 20px;
        li {
            margin-bottom: 5px;
            &:before {
                content: "\f054";
                font-weight: 700;
                font-family: 'Font Awesome 5 Free';
                display: inline-block;
                margin-left: -1.5em;
                width: 1.5em;
                height: 1.5em;
                font-size: 0.7em;
                color: $bleu-500;
              }
        }
    }
    .formateur img {
        object-fit: cover;
        max-width: 200px;
    }
    #formation-objectifs {
        .col {
            min-width: 300px;
        }
    }
    #formation-programme {
        a {
            cursor: pointer;
        }
        div, svg {
            transition: all 0.3s ease;
        }
        a[aria-expanded="true"] {
            div, svg {
                color: $bleu-500;
            }
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
