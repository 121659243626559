.contenu-photo-custom {
  margin: 30px 0;
  .container {
    div {
      margin: 20px;
    }
    .text {
      h3 {
        margin-bottom: 15px;
      }
      div {
        margin: 0;
      }
      @media (max-width: 992px) {
        order: 1 !important;
      }
    }
    .image {
      img {
        // border-radius: 20px;
        height: 250px;
        width: 350px;
        object-fit: cover;
        @media (max-width: 415px) {
          width: 275px;
          height: 150px;
        }
      }
      @media (max-width: 992px) {
        order: 2 !important;
        margin: 0 !important;
      }
    }
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
