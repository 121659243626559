#page-offre {
    .icon {
        width: 60px;
    }
    ul {
        li {
            margin: 0;
            padding: 10px 0 10px 40px;
            list-style: none;
            background-image: url('../icon/transport.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 20px;
        }
    }
}
