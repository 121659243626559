#page-header {
  overflow: hidden;
  .page-header-color {
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .header {
    height: 75vh;
    background-size: cover;
    background-position: center;
    padding: 90px 0 30px 0;
    border-bottom-left-radius: 50% 25%;
    border-bottom-right-radius: 50% 25%;
    margin-right: -10%;
    margin-left: -10%;
    padding-left: 10%;
    padding-right: 10%;
    @include media-breakpoint-down(md) {
      height: 100% !important;
    }
  }
  .texte {
    line-height: 160%;
    font-size: 1.1em;
    @include media-breakpoint-down(md) {
      font-size: 1em;
    }
  }
  h1,
  .texte {
    text-shadow: 1px 1px 2px rgba($bleu-700, 0.4);
  }
  h1 {
    line-height: 150%;
    @include media-breakpoint-down(md) {
      font-size: 1.2em;
    }
    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 5px;
      background-color: $jaune;
      border-radius: 10px;
      margin: 30px auto;
      @include media-breakpoint-down(md) {
        margin: 20px auto;
      }
    }
  }
}

.small-header {
  min-height: 300px;
  background-size: cover;
  background-position: center;
  padding: 100px 0 30px 0;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
  margin-right: -10%;
  margin-left: -10%;
  padding-left: 10%;
  padding-right: 10%;
  h1 {
    @include media-breakpoint-down(md) {
      font-size: 1.2em;
    }
    text-shadow: 1px 1px 2px rgba($bleu-700, 0.4);
    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 5px;
      background-color: $jaune;
      border-radius: 10px;
      margin: 20px auto;
    }
  }
}
