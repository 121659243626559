#services-accompagnement {
    background-color: $gris-light;
    .icon:before {
        content: "";
        left: 10px;
        bottom: -5px;
        position: absolute;
        display: block;
        height: 80px;
        width: 80px;
        background-color: $bleu-300;
        border-radius: 100%;
    }
    .decoration {
        top: 0px;
        left: -350px;
        width: 500px;
        transform: rotate(-70deg);
        opacity: 0.7;
        .forme {
            fill: $bleu-300;
            opacity: 0.7;
        }
    }
}
