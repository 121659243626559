.contenu-photo {
  div:first-child {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }
  img {
    object-fit: cover;
    width: 39%;
    @include media-breakpoint-down(md) {
      position: relative !important;
      width: 100%;
      height: 300px !important;
    }
  }
  &.left {
    .contenu {
      margin-left: 40%;
    }
  }
  &.right {
    img {
      right: 0;
    }
    .contenu {
      margin-right: 40%;
      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .contenu {
    @include media-breakpoint-down(md) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      h2 {
        margin: 15px 0;
      }
      h3 {
        font-size: 1.2em !important;
        margin-bottom: 0;
      }
    }
  }
}
