#equipe-metiers {
    background-color: $gris-light;
    .decoration {
        top: -90px;
        right: -330px;
        width: 500px;
        transform: rotate(-70deg);
        opacity: 0.5;
        .forme {
            display: none;
        }
    }
}