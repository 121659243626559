#service-expertise-technique {
    .img-mockup {
        height: 200px;
        img {
            object-fit: cover;
        }
    }
    .decoration {
        opacity: 0.7;
        &.deco1 {
            top: 0px;
            left: -100px;
            width: 230px;
            height: 150px;
            transform: rotate(20deg);
            .forme {
                fill: $bleu-400;
                opacity: 0.3;
            }
        }
        &.deco2 {
            bottom: -60px;
            right: -100px;
            width: 300px;
            height: 150px;
            transform: rotate(-40deg);
            .forme {
                display: none;
            }
        }
    }
}