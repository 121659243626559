#services-realisations {
    .etapes {
        counter-reset: etape;
        .icon:before {
            counter-increment: etape;
            content: counter(etape);
            font-size: 3.5em;
            left: -5px;
            top: -40px;
            z-index: -1;
            font-weight: 700;
            color: $jaune;
            position: absolute;
        }
    }
    .bg-ordinateur {
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
        @include media-breakpoint-down(md) {
            background: none !important;
        }
    }
}
