#home-presentation {
    background-color: $bleu-700;
    .contenu {
        line-height: 180%;
        h3 {
            font-size: 1.3em;
        }
    }
    .decoration {
        top: -40px;
        left: -130px;
        width: 280px;
        transform: rotate(-40deg);
        .forme {
            opacity: 0.6;
            fill: $bleu-300 !important;
        }
        .contour {
            opacity: 0.4;
            stroke: $bleu-500 !important;
        }
    }
}